.list_container {
  padding: 1.5rem;
  margin-top: 50px;
}

.top_row {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.table_invoice:hover {
  cursor: pointer;
}

.searchList {
  margin-bottom: 50px;
}