.messaging__container {
  display: flex;
  flex-direction: row;
}

.messaging__preview-container {
  padding: 0 1rem;
  flex: 1;
}
.messaging__preview {
  border: 1px solid black;
  border-radius: 9px;
  height: 90%;
}
.messaging__preview-text {
  text-align: left;
  word-wrap: break-word;
  max-width: 500px;
  margin: 0 auto;
  white-space: pre-line;
}

.messaging__log_sociedad {
  width: 20%;
  height: 20%;
}

.messaging__form {
  flex: 1;
  margin-top: 1rem;
}

.messaging__form-top .messaging__send-button {
  float: right;
  margin-right: 2rem;
}

.messaging__title {
  font-weight: 300;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
}

.message__body {
  width: 97%;
  height: 65%;
  font-size: 1rem;
  background-color: #ffff;
  padding: 0.4rem;
}

.subject_text {
  width: 97%;
  font-size: 1rem;
  padding: 0.4rem;
  background-color: #ffff;
}
