.settingsBtnContainer,
.settingsBtnContainerSecond {
    display: flex;
    justify-content: end;
}

.settingsBtnContainerSecond {
    margin-top: 20px;
}

.rowContainer {
    display: flex;
    flex-direction: column-reverse;
    
    @media only screen and (min-width: 992px) {
        justify-content: space-between;
        flex-direction: row;
    } 
    
    .btnSave {
        margin-left: 20px;
    }

    .stateIndicator {
        display: inline;
        margin: 0 15px 0px 15px;
        background-color: #4088e7a6;
        padding: 6px;
        color: white;
        align-self: center;
    }

    .dateText {
        font-weight: 700;
        margin-bottom: 0;
    }

    .printSpace {
        margin-left: 15px;
    }
}

.container {
    display: flex;
    flex-direction: column;

}