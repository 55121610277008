.container {
    margin: 0 auto;
    height: 40%;
    width: 80%;
    background-color: #eeede8;
  }


  .description {
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
  }