.posgrade_form {
  border: 1px solid black;
  padding: 0.5em;
}

.top_row_first {
  flex-basis: 75%;
  margin-right: 4em;
}

.inline_row_form {
  display: flex;
  flex-direction: column;
}

.remove_style {
  cursor: pointer;
}

.select_geographical {
  width: 100%;
  margin: 0 0.5rem;
  margin-top: 0.5rem;
}
.error_border {
  border-bottom: 1px solid red;
}




.top_row_setting__info {
  display: flex;
}

.top_row_setting__info div:first-child {
  margin-right: 1rem;
}

.member_delete_banner {
  background-color: #ff5722;
}
@media only screen and (min-width: 768px) {
  .inline_row_form {
    flex-direction: row;
    flex-direction: row;
  }
  .text_divider {
    margin-right: 0.6em;
  }
}
