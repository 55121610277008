.servicesContainer {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }

    .wrapper {
        @media only screen and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .rateText {
      max-width: 100px;
    }
    }
}

.service_drop_down {
    width: 100%;

    #react-select-5-listbox, .service__menu {
        z-index: 99999 !important;
        background-color: black;
    }
}

.servicesContainer .textField {
    margin-right: 20px;
    @media only screen and (min-width: 992px) {
        width: 50%;
        max-width: 150px;
    }
}

.servicesContainer .textArea {
    
    @media only screen and (min-width: 992px) {
        margin-left: 10px;
        margin-top: 20px;
        width: 100%;
    }
    
}

.servicesContainer .addBtn {
    margin-top: 20px;
}