@import "../../../styles/theme.module.scss";
@import "../../../styles/space.module.scss";

@media screen {
    .contianer {
        display: none;
    }
  }

@media print {
    .contianer {
        //width: 9.5in;
    }
    .brand_row {
        display: flex;
    }
    .brand_logo_smaller {
        width: 100px;
    }
    .brand_name_small {
        font-size: 18px;
        margin-bottom: 1px;
        font-weight: 700;
    }
    .rnc_small {
        margin-top: 1px;
        font-weight: 700;
        text-align: center;
    }
    .title {
        text-align: center;
    }
    .squenceRow {
        display: flex;
        justify-content: space-between;
    }
    .invoiceNum {
        margin-bottom: 2px;
    }
    .abonoNum {
        margin-top: 5px;
    }
    .bolder {
        font-weight: 700;
        margin-right: 8px;
    }
    .ownerContainer {
        display: flex;
        margin-top: 15px;
    }
    .forWho {
        font-weight: 700;
        margin-right: 10px;
    }
    .owner {
        margin-top: 0;
        margin-bottom: 0;
    }
    .amount {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 15px;
        font-weight: 700;
        text-align: end;
        font-size: 20px;
    }

    .symbol {
        font-weight: 500;
    }

    .detailQuota {
        display: flex;
        margin-top: 30px;
    }
    
    .detail {
        margin-left: 10px;
        border: 1.5px solid $grey_medium;
        padding: 5px;
    }

    .notesContainer {
        flex-grow: 2;
    }
    .notes {
    }

    .leyend {
        display: flex;
    }
    .amount_leyend {
      font-weight: 500;
      margin-top: 0;
      margin-left: 5px;
      margin-bottom: 0;
    }
    .sing {
      //  page-break-after: always;
    }
  }


