@import '../../../styles/space.module.scss';

.inputContainer {
    display: flex;

    .inputMedium {
        width: 90%;
    }
    .tableVerticalSpace {
        margin-top: $space_separator-s;
    }
    .inputSmall {
        width: 45%;
    }
    .inputRightSpace {
        margin-right: $space_divider-s;
    }
}