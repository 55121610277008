.containerPaymentType {
    flex-direction: column;
}

.labelpaymentType {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
}