.delete_banner {
   background-color: #d84315; 
}

.reason_container {
   margin-top: 15px;
   display: flex;
   flex-direction: column;
}

.reason_textarea {
   resize: none;
}

.reason_error {
   color: red;
}

.reason_error_textarea {
   border-color: red;
}