.top_row_first_ {
width: 90%;
}

.input_search {
  flex-grow: 2;
  width: 55%;
}

.filter_group.MuiFormControl-root {
    margin-left: 30px;
}