.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media only screen and (min-width: 768px) {
        flex-direction: row;  
    }
    @media only screen and (min-width: 992px) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.ncfContainer {
    display: flex;

    .itebisLabel {
        margin-left: 15px;
    }
}