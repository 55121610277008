.form {
    margin-top: 20px;
    margin-bottom: 40px;
}

.taxNumber {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.wrapperCurrentNumber {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.taxNumberInput {
    flex-grow: 2;
}

.inputSpace {
    margin-top: 15px;
}

.endSecuence {
    margin-top: 15px;
    margin-left: 15px;
}

.btnSubmit {
    width: 100%;
    margin-top: 35px !important;
}

.pickerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}