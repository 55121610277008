.report_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.invoice_summary__container {
  margin: 0 auto;
  height: 40%;
  width: 80%;
  background-color: #eeede8;
}

.invoice_description {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
