.login_container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo_container {
  margin: 0 auto;
  margin-top: 3em;
  margin-bottom: 2em;
}

.txt_container {
  display: flex;
  flex-direction: column;
  
  width: 85%;
  padding: 15px;
  border: 1px solid rgba(70,130,169,0.2);
  border-radius: 8px;
  background-color: #F5F5F5;
  box-shadow: 10px 10px 5px 0px rgba(70,130,169,0.3);
-webkit-box-shadow: 10px 10px 5px 0px rgba(70,130,169,0.3);
-moz-box-shadow: 10px 10px 5px 0px rgba(70,130,169,0.3);
  @media only screen and (min-width: 768px) {
    width: 35%;
  }
}

.txt_space {
  margin-bottom: 2rem;
}

.MuiGrid-root .login_txt {
  margin: 1rem 0;
}
