.invoice_detail__container {
  height: 40%;
  background-color: #eeede8;
}

.invoice_detail_description {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
