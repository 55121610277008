
.header__charge {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.header__charge h1 {
  margin-top: 0;
}

.header__charge a {
  display: inherit;
}