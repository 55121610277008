/*Company List*/
.btnNewContainer {
  display: flex;
  justify-content: end;
  margin-bottom: 50px;
}

.searchBarContainer {
    margin-bottom: 50px;
}


/*Company Form*/
.companyFormContainer {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.companyFormContainer .textField {
  margin-bottom: 30px;
}