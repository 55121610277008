@import '../../../styles/space.module.scss';
@import '../../../styles/theme.module.scss';

.last_updated__container {
  margin-bottom: $space_separator-l;
}

.aspiringTag {
  background-color: $background_tag_1;
  color: $background_soft_grey;
  border-radius: 20%;
  padding: 1px 2px;
  margin-right: 2px;
  font-size: 10px;
  font-weight: 700;
}

.aspiringLine {
  tr {
    border-bottom-color: red;
  }
}