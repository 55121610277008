@import '../../../styles/space.module.scss';

.top_row_setting {
     
        display: flex;
        flex-direction: row;
        justify-content: space-between;
     

    .inputMedium {
        width: 90%;
    }
    .tableVerticalSpace {
        margin-top: $space_separator-s;
    }
    .inputSmall {
        width: 45%;
    }
    .inputRightSpace {
        margin-right: $space_divider-s;
    }
}