@import "../../../styles/theme.module.scss";
@import "../../../styles/space.module.scss";


@media print {
  .container {
    display: none;
  }
}
.invoiceNum {
    display: inline-block;
    background-color: $soft_blue_opacity;
    padding: 8px;
    margin-bottom: $space_divider-m;
    .bolder {
        font-weight: 700;
        margin-right: $space_divider-xs;
    }
}

.title {
    text-align: center;
}

.ownerContainer {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
        justify-content: space-between;
        flex-direction: row;
    } 
}

.owner {
    margin-bottom: $space_divider-s;
    @media only screen and (min-width: 992px) {
        flex-grow: 3;
        margin-right: $space_divider-s;
    }
}

.noteContainer {
    margin-top: $space_divider-s;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 992px) {
        flex-direction: row;
        margin-top: $space_divider-m;
    }
    .notes {
      
      @media only screen and (min-width: 992px) {
        flex-grow: 2;
        margin-right: $space_divider-s;
      }
    }
  .totalDetail {
    flex-grow: 1;
    border: 1.5px solid $grey_medium;
    padding: 5px;
    margin-top: $space_divider-xs;
    @media only screen and (min-width: 992px) {
        margin-top: 0; 
    }
      .leyend {
        display: flex;
        .leyendLabel {
            display: inline;
            margin-right: $space_divider-xs;
        }
        .leyendValue {
            display: inline;
            margin-top: 0;
            font-weight: 700;
        }
      }
  }  
}

.submit {
    margin-top: $space_divider-s;
}