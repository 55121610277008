@import "../../styles/theme.module.scss";
@import "../../styles/general.module.scss";

.container {
    @media only screen and (min-width: $medium-device) {
        width: 70%;
        align-items: center;
        margin: 0 auto;
    }
}

.titleContainer {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $medium-device) {
        flex-direction: row; 
        width: 70%;
    }

    .title {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 2px;
        width: 90%;
    }
    .dateText {
        font-weight: 700;
        margin-top: 2px;
        margin-bottom: 2px;
        text-align: center;
        
        @media only screen and (min-width: $medium-device) {
            margin-top: 0px;
        }
    }
}

.newContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;

    @media only screen and (min-width: $medium-device) {
        justify-content: space-between;
        width: 70%;
    }

    .numberLabel {
        font-weight: 700;
    }
    .counter {
        padding: 7px;
        background-color: $soft_blue;
        
        min-width: 80px;
        text-align: center;
        color: white;
        font-weight: 700;
    }
}

.supplierContainer {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $medium-device) {
        flex-direction: row;
    }
}

.supplier {
    margin-bottom: 30px;
    @media only screen and (min-width: $medium-device) {
        width: 55%;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.secondContainer {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $medium-device) {
      flex-direction: row;
      align-items: flex-end;
    }
}

.notes {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 25px;
    @media only screen and (min-width: $medium-device) {
    margin-right: 20px;
    margin-bottom: 0px;
    width: 55%;
    }
}