@import '../../../styles/theme.module.scss';
@import '../../../styles/space.module.scss';

.container {
    background-color: $background_soft_grey;
    width: 50%;
    padding: $space_divider-s;
    margin: 0 auto;
    margin-top: 200px;
}

.dateContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}