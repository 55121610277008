@import '../../../styles/space.module.scss';

.inputContainer {
    display: flex;

    .inputMedium {
        width: 90%;
    }
    .inputSmall {
        width: 45%;
    }
    .inputRightSpace {
        margin-right: $space_divider-s;
    }

    .react-select__control {
        height: 56px;
    }
    .boxThis {
        display: flex;
        flex-direction: column;
    }
   .extra {
        border: none;
        border-bottom: 1px solid;
    }
}

