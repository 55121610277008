@import '../../../styles/theme.module.scss';

.row_uncompleted {
    background-color: #ff712f96;
  }
  .row_deceased {
    background-color: #dd76f788;
  }
  .row_normal {
    background-color: transparent;
  }

  .aspiringTag {
    background-color: $background_tag_1;
    color: $background_soft_grey;
    border-radius: 20%;
    padding: 1px 2px;
    margin-right: 2px;
    font-size: 10px;
    font-weight: 700;
  }