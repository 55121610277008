@import '../../styles//space.module.scss';

.servicesForm {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
  
  .accountContainer {
    display: flex;
    flex-direction: row;
  
     .box {
        display: flex;
        flex-direction: column;
      }

    .inputMedium {
      width: 90%;
    }
   
    .inputRightSpace {
      margin-right: $space_divider-s;
    }
  }

  .inputBottomSpace {
    margin-bottom: $space_divider-m;
   }
  
  .priceContainer {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    .input90 {
      width: 70%;
    }
    .currencySelect {
      margin-top: 16px;
      width: 30%;
    }
  }

  .btnContainer {
    margin-top: $space_separator-s;
  }
  
  .error_border {
    border: 1px solid red;
  }

  .serviceFor {
    margin-bottom: $space_divider-s;
  }

  .serviceForTitle {
    margin-bottom: 0;
    font-weight: 400;
  }
}