.container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    @media only screen and (min-width: 992px) {
      flex-direction: row;
    }

    .inputContainer {
        flex-basis: 70%;
        margin-right: 20px;

        .btnContainer {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
        }
    }

    .services {
        margin-bottom: 10px;
    }
}