@import '../../../../styles/theme.module.scss';

.service__menu {
    z-index: 999!important;
  }
  
  .service__control {
      height: 60px;
  }
  
  .service_drop_down {
    flex: 2;
    flex-basis: 20%;
  }
  
  .service_drop_down_error {
    border: 1px solid red;
  }
  .service__single-value {
    width: 100%;
  }

  .personForm {
    display: flex;
    flex-direction: column;
  }

  .aspiringTag {
    background-color: $background_tag_1;
    color: $background_soft_grey;
    border-radius: 20%;
    padding: 1px 2px;
    margin-right: 2px;
    font-size: 10px;
    font-weight: 700;
  }