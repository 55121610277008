.contentContainer {
   width: 100%;
}
@media only print { 
    .container, .contentContainer {
       margin-top: 0 !important;
       padding-right: 5px;
       padding-left: 5px;
    }
    .menuContainer {
        display: none;
     }
}