@import '../../styles/space.module.scss';

.container {
    display: flex;
    flex-direction: column;
}

.btnContainer {
    display: flex;
    margin-top: $space_separator-m;

    :nth-child(1) {
        margin-right: $space_divider-s;
    }
}

.consultContainer {
    .title {
        text-align: center;
        font-size: 25px;
    }
    .datesContainer {
        text-align: center;
        .date {
            font-size: 18px;
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    .count {
        text-align: center;
        margin-top: 15px;
        font-size: 18px;
    }
}