@media only print {
  .invoice_brand_row {
    display: flex;
    flex-direction: row;
  }

  .invoice_brand_name {
    margin-left: 10px;
    font-size: 18px;
  }

  .invoice_date {
    text-align:right;
    width: 100%;
  }

  .invoice_label {
    font-weight: 500;
  }

  .invoice_status {
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    border-bottom: 1px solid #bdbdbd;
  }
  
  .invoice_num {
    font-weight: bold;
    text-align: right;
  }
  .invoice_client_container {
    margin-bottom: 16px;
  }
  .invoice_format__full_name {
    margin: 14px 0;
    font-size: 20px;
  }

  .invoice_format__identifier {
    margin: 0;
    font-size: 14px;
  }

  .invoice_format__detail_title {
    text-align: center;
    font-size: 18px;
  }

  .invoice_format__services_header {
    margin-top: 5px;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
  }

  .invoice_format__service_title {
    font-weight: bold;
  }

  .invoice_format__services_body {
    display: flex;
    flex-direction: column;
  }

  .invoice_format__row {
    display: flex;
    justify-content: row;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .invoice_format__description {
    width: 580px;
    margin: 0 1px;
  }

  .invoice_format__quantity {
    width: 110px;
    margin: 0 1px;
  }

  .invoice_format__price,
  .invoice_format__total {
    width: 160px;
    margin: 0 1px;
  }

  .invoice_payment_type_description {
    font-style: italic;
    text-align: end;
  }
}

.invoice_format__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.invoice_total_container {
  display: flex;
  flex-direction: row;
}

.invoice_total_description {
  margin-right: 2px;
  font-size: 15px;
  font-weight: 500;
}

.invoice_format_total_price {
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
}

.invoice_format__net_total {
  margin-top: 1px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  font-weight: bold;
  font-size: 17px;
}
